import CookieConsent from 'react-cookie-consent';
import ModalTitleText from '../../pages/usuario/components/ModalTitleText';
import { lang } from '../../functions/lang';
import styles from './styles.module.css';
/**
 *
 * @param {*} text texto configurado
 * @param {*} keySearch parâmetro no texto a ser modificado no texto como link para abrir modal
 *  ex: [[Clique Aqui]] >> <Modal>Clique Aqui</Modal>
 * 
 * 
 @returns {
  part1: str, part2:str, Component -> {$part1}${Component}${part2}
 }
 */
const configDescriptionWithLinkModal = (
  text,
  keySearchIni = '\\[\\[',
  keySearchEnd = '\\]\\]'
) => {
  if (!text) {
    return '';
  }

  if (!keySearchIni || !keySearchEnd) {
    return text;
  }
  if (!text) {
    return {};
  }

  if (!keySearchIni || !keySearchEnd) {
    return { partInicial: text, textoButton: '', partFinal: '' };
  }

  const regex = new RegExp(`${keySearchIni}(.*?)${keySearchEnd}`, 'g');
  let partInicial = '';
  let textoButton = '';
  let partFinal = '';

  let match;
  let lastIndex = 0;

  while ((match = regex.exec(text)) !== null) {
    textoButton = match[1];
    partInicial += text.substring(lastIndex, match.index);
    lastIndex = match.index + match[0].length;
  }

  partFinal = text.substring(lastIndex);

  return { partInicial, textoButton, partFinal };
};
export default function CookieConsentMod(props) {
  const cookieDesc = configDescriptionWithLinkModal(
    props.pageProps?.wl?.consentimento_cookie_text ??
      props.pageProps?.wlData?.consentimento_cookie_text ??
      '',
    '\\[\\[',
    '\\]\\]'
  );
  return (
    <CookieConsent
      className={styles.content}
      location="top"
      buttonText="Aceitar Cookies"
      cookieName="ElectyWebAppConsentCookie"
      // debug={true}
      style={{
        background: '#3E3F3F',
        opacity: 0.95,
        zIndex: 900,
        marginTop: 'calc(100vh - 250px)',
        textAlign: 'center',
      }}
      buttonStyle={{
        // width: '100%',
        color: 'black',
        backgroundColor: props.pageProps?.wl?.cor_tema_secundaria,
        borderRadius: 5,
        fontSize: '16px',
      }}
      buttonWrapperClasses="d-flex w-sm-100 f-sm-col lg-center"
    >
      <span style={{ fontSize: '16px' }}>
        {cookieDesc.partInicial}
        <a className={styles.linkModal}>
          <ModalTitleText
            wl={props.pageProps?.wl}
            src-icon-close={'/images/icons/button/close-white.svg'}
            content={lang('usuario/index.politica-cookies', '')}
          />
        </a>
        {cookieDesc.partFinal}
      </span>
    </CookieConsent>
  );
}

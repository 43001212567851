import Head from 'next/head';
import '../styles/globals.css';
import '../styles/medias.css';
import '../styles/responsive.css';
import CookieConsentMod from '../components/CookieConsentMod';
import { ErrorBoundary } from "./../components/Error/ErrorBoundary";

function Electy({ Component, pageProps }) {
  return (
    <>
      <CookieConsentMod pageProps={pageProps} />
      <ErrorBoundary>
        <Component {...pageProps} />
      </ErrorBoundary>
    </>
  );
}

export default Electy;

/**
 * Get array key in Json file auto recursive
 * @param {*} key >> home.bannerInfo.title >> file ==> (home.json) => contains ..., {..., "bannerInfo": {"title": "Titile Description Here", ... }, ...
 * @param {*} altValue when case not found value in key by any cause, returns default value: altValue
 * @returns value of key requested
 */
export const lang = (key, altValue) => {
	try {
	
		let keys = key.split('.')
	
		const fileName = keys.shift()
		
		const idiom = process.env.NEXT_PUBLIC_LANG
		
		const translations = require(`/src/lang/${idiom}/${fileName}.json`)
	
		const keyResult = transGetKeyRecursive(keys, translations)
		
		return keyResult ? keyResult : altValue  
	
	} catch(e) {
		return altValue
	}
}

const transGetKeyRecursive = (keysList, obtainedItem) => {
	try {

		const nextIndex = keysList.shift()
	
		if(nextIndex) {
			obtainedItem = obtainedItem[nextIndex]
			return transGetKeyRecursive(keysList, obtainedItem)
		}
		
		return obtainedItem ?? false

	} catch(e) {
		console.error('error index transGetKeyRecursive')
		console.error(e)
		return false
	}
}
import React, { Fragment } from 'react';
import styles from './styles.module.css';
import { useState } from 'react';
/**
 *
 * @param {name} text - Conteúdo do botão
 * @param {handleOnClick} function - Função com redirecionamento do usuário
 * @returns
 */
const ButtonBorder = (props) => {
  const {
    name,
    handleOnClick,
    disable,
    arrowLeft,
    className,
    colorButton,
    colorButtonBorder,
    size,
    wl,
    onFocus,
    onBlur,
    onMouseOver,
    onMouseLeave,
    ...restProps
  } = props;

  const [styleFocus, setStyleFocus] = useState();

  const focus = (event) => {
    if (onFocus) {
      onFocus(event);
    }
    setStyleFocus({
      border: `1px ${wl?.cor_tema_4} solid`,
      background: wl?.cor_tema_4,
      boxShadow: `0 0 0.5rem ${wl?.cor_tema_4}`,
      outline: 0,
    });
  };

  const blur = (event) => {
    if (onBlur) {
      onBlur(event);
    }
    setStyleFocus({
      border: `1px ${wl?.cor_hover_botao_borda} solid`,
    });
  };

  const hover = (event) => {
    if (onMouseOver) {
      onMouseOver(event);
    }

    if (!disable) {
      className == 'selected'
        ? {}
        : setStyleFocus({ background: wl?.cor_hover_botao_borda });
    }
  };

  const leave = (event) => {
    if (onMouseLeave) {
      onMouseLeave(event);
    }

    className == 'selected' ? {} : setStyleFocus({ background: 'transparent' });
  };

  return (
    <Fragment>
      <button
        style={{
          border: disable
            ? `1px ${props.wl.cor_tema_botao_borda_desabilitado} solid`
            : className == 'selected'
            ? `1px ${wl.cor_hover_botao_borda} solid`
            : props.wl?.cor_tema_botao_borda
            ? `0.06rem solid ${props.wl?.cor_tema_primaria}`
            : '',
          color: disable
            ? wl.cor_tema_botao_borda_desabilitado
            : props.wl?.cor_tema_botao_borda,
          background: className == 'selected' ? wl.cor_hover_botao_borda : '',
          ...styleFocus,
        }}
        onFocus={focus}
        onBlur={blur}
        onMouseOver={hover}
        onMouseLeave={leave}
        className={[
          arrowLeft
            ? `${styles.button} ${styles.arrowLeft} ${
                className == 'selected' ? styles.selected : className
              }`
            : `${styles.button} ${
                className == 'selected' ? styles.selected : className
              }`,
            styles[`button-size-${size}`]
          ].join(' ')
        }
        onClick={handleOnClick}
        disabled={disable}
        {...restProps}
      >
        {/** Se na chamada do componente for atribuido o valor "true" para a propriedade  arrowLeft*/}
        {arrowLeft ? (
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.47144 0.528636C9.73179 0.788986 9.73179 1.2111 9.47144 1.47145L2.60952 8.33337H17C17.3682 8.33337 17.6667 8.63185 17.6667 9.00004C17.6667 9.36823 17.3682 9.66671 17 9.66671H2.60952L9.47144 16.5286C9.73179 16.789 9.73179 17.2111 9.47144 17.4714C9.21109 17.7318 8.78899 17.7318 8.52864 17.4714L0.528636 9.47144C0.268287 9.21109 0.268287 8.78899 0.528636 8.52864L8.52864 0.528636C8.78899 0.268287 9.21109 0.268287 9.47144 0.528636Z"
              fill="white"
              stroke={props.wl?.cor_tema_botao_borda}
            />
          </svg>
        ) : (
          ''
        )}
        {name}
      </button>
    </Fragment>
  );
};

export default ButtonBorder;

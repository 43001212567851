import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import ButtonBorder from '../../../../components/ButtonBorder';

const ModalTitleText = (props) => {
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Button onClick={handleClickOpen('body')} classes={styles.root}>
        {props.content?.title ?? ''}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className={styles.dialog}
      >
        <img onClick={handleClose} src={props['src-icon-close']} />
        <DialogTitle
          id="scroll-dialog-title"
          sx={{
            marginTop: 4,
            fontSize: 14,
          }}
        >
          <h1 className="mobile__h5__refactory">
            {props.content?.title ?? ''}
          </h1>
        </DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.content.paragraph?.map((item, index) => (
              <Fragment key={index}>
                <h3>{item?.title ?? ''}</h3>
                <p>
                  {item.items?.map((tagP, tagIndex) => (
                    <Fragment key={tagIndex}>
                      <p className={`mobile__p4`}>{tagP.p}</p>
                    </Fragment>
                  )) ?? ''}
                </p>
              </Fragment>
            ))}
            <div
              style={{
                marginTop: '40px',
              }}
            >
              <ButtonBorder
                wl={props.wl}
                name="Fechar"
                handleOnClick={handleClose}
              />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ModalTitleText;
